import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Cookies from 'universal-cookie';

import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBInput,
    MDBBtn,
  } from 'mdbreact';

import { checkAuthenticated } from '../../components/admin/auth';
import InterB2BAxios from '../../Service/InterB2BAxios';

function AdminLoginPage(props) {
    const [authenticated, setAuthenticated] = useState(null);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const cookies = new Cookies();
    const navigate = useNavigate();

    let interAxios = new InterB2BAxios(this, 'AdminLoginPage');

    useEffect(() => {
        return checkAuthenticated(setAuthenticated);
    })
    if (authenticated === null) {
        return <div>Please wait while loading...</div>
    }
    if (authenticated) {
        navigate('/admin')
    }
    function doLogin(e) {
        interAxios.post('/signin', {username: username, password: password}, function(response) {
            cookies.set('authtoken', {token: response.data.authToken}, { path: '/admin'});
            navigate('/admin');
        }, function() {
            console.log('Itt vagyok')
            setErrorMessage('Wrong username or password');
        })
    }

    return <div>
        <h1>Login to admin area</h1>
        <div>
            <MDBContainer>
                {errorMessage !== '' ?
                <MDBRow>
                    <MDBCol>
                        {errorMessage}
                    </MDBCol>
                </MDBRow> : false}
                <MDBRow>
                    <MDBCol>
                        <MDBInput 
                            id="username" 
                            type="text" 
                            label="Username"
                            style={{backgroundColor: 'white'}}
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol>
                        <MDBInput
                            id="password" 
                            type="password"
                            label="Password"
                            style={{backgroundColor: 'white'}}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol>
                        <MDBBtn onClick={doLogin}>Login</MDBBtn>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </div>
    </div>
}

export default AdminLoginPage;