import React from 'react';
import anchor from '../../assets/media/images/anchor.png'
import {
  MDBRow,
  MDBCol,
  MDBIcon
} from 'mdbreact';

class PierDisplay extends React.Component {
  render() {
    return(
      <div className="pierdispaly">
        <MDBRow className="m-0 p-0" style={{height: "100%"}}>
          <MDBCol size="2" className="text-center p-0" middle>
            <img className="ancor-icon" src={anchor}/>
          </MDBCol>
          <MDBCol size="10" className="text-left pt-0 px-0" middle>
            <span className="result-pier-name-text text-white">{this.props.pier}</span>
          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}

export default PierDisplay;
