import React from 'react';
import HorizontalPicker from '../picker/HorizontalPicker';

class LinesDisplay extends React.Component {
  render() {
    return (
      <div>
        <HorizontalPicker list={this.props.lines} selected={this.props.selected} onSelect={this.props.onSelect}/>
      </div>
    )
  }
}

export default LinesDisplay;
