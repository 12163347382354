import React from 'react';

import panzoom from 'pan-zoom';

import {
  MDBIcon,
  MDBRow
} from 'mdbreact';

class LineComponent extends React.Component {
  render() {
    return (
        <>
          <div style={{transform: `translateY(${this.props.y}px)`}} className="line-time">
            <div style={{width: "80px", height: this.props.zoom * (this.props.piers["GE Jardin-Anglais"] - this.props.piers["GE Mt-Blanc"])/100-1, backgroundColor: "#c6e5ef", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff", alignContent: "center", display: "grid"}} className="pr-1">
              <MDBRow end className="m-0">
              <span className="pier-text-2">Genève</span><br/>
              </MDBRow>
              <MDBRow end className="m-0">
              <span className="pier-text">Mt-Blanc</span>
              </MDBRow>
            </div>
            <div style={{width: "80px", height: this.props.zoom * (this.props.piers["GE Pâquis"] - this.props.piers["GE Jardin-Anglais"]) /100, backgroundColor: "#e2f2f8", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="pier-text-format pr-1">
             <span className="pier-text">J.-Anglais</span>
            </div>
            <div style={{width: "80px", height: this.props.zoom * (this.props.piers["GE Eaux-Vives"] - this.props.piers["GE Pâquis"]) /100, backgroundColor: "#c6e5ef", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="pier-text-format pr-1">
             <span className="pier-text">Pâquis</span>
            </div>
            <div style={{width: "80px", height: this.props.zoom * (this.props.piers["La Belotte"] - this.props.piers["GE Eaux-Vives"]) /100, backgroundColor: "#e2f2f8", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="pier-text-format pr-1">
             <span className="pier-text">Eaux-Vives</span>
            </div>
            <div style={{width: "80px", height: this.props.zoom * (this.props.piers["Bellevue"] - this.props.piers["La Belotte"]) /100, backgroundColor: "#c6e5ef", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="pier-text-format pr-1">
             <span className="pier-text">La Belotte</span>
            </div>
            <div style={{width: "80px", height: this.props.zoom * (this.props.piers["Corsier"] - this.props.piers["Bellevue"]) /100, backgroundColor: "#e2f2f8", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="pier-text-format pr-1">
             <span className="pier-text">Bellevue</span>
            </div>
            <div style={{width: "80px", height: this.props.zoom * (this.props.piers["Anières"] - this.props.piers["Corsier"]) /100, backgroundColor: "#c6e5ef", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="pier-text-format pr-1">
             <span className="pier-text">Corsier</span>
            </div>
            <div style={{width: "80px", height: this.props.zoom * (this.props.piers["Versoix"] - this.props.piers["Anières"]) /100, backgroundColor: "#e2f2f8", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="pier-text-format pr-1">
             <span className="pier-text">Anières</span>
            </div>
            <div style={{width: "80px", height: this.props.zoom * (this.props.piers["Coppet"] - this.props.piers["Versoix"]) /100, backgroundColor: "#c6e5ef", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="pier-text-format pr-1">
             <span className="pier-text">Versoix</span>
            </div>
            <div style={{width: "80px", height: this.props.zoom * (this.props.piers["Hermance"] - this.props.piers["Coppet"]) /100, backgroundColor: "#e2f2f8", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="pier-text-format pr-1">
             <span className="pier-text">Coppet</span>
            </div>
            <div style={{width: "80px", height: this.props.zoom * (this.props.piers["Céligny"] - this.props.piers["Hermance"]) /100, backgroundColor: "#c6e5ef", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="pier-text-format pr-1">
             <span className="pier-text">Hermance</span>
            </div>
            <div style={{width: "80px", height: this.props.zoom * (this.props.piers["Nyon"] - this.props.piers["Céligny"]) /100, backgroundColor: "#e2f2f8", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="pier-text-format pr-1">
             <span className="pier-text">Céligny</span>
            </div>
            <div style={{width: "80px", height: this.props.zoom * (this.props.piers["Nernier"] - this.props.piers["Nyon"]) /100, backgroundColor: "#c6e5ef", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="pier-text-format pr-1">
             <span className="pier-text-2">Nyon</span>
            </div>
            <div style={{width: "80px", height: this.props.zoom * (this.props.piers["Yvoire"] - this.props.piers["Nernier"]) /100, backgroundColor: "#e2f2f8", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="pier-text-format pr-1">
             <span className="pier-text">Nernier</span>
            </div>
            <div style={{width: "80px", height: this.props.zoom * (this.props.piers["Rolle"] - this.props.piers["Yvoire"]) /100, backgroundColor: "#c6e5ef", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="pier-text-format pr-1">
             <span className="pier-text-2">Yvoire</span>
            </div>
            <div style={{width: "80px", height: this.props.zoom * (this.props.piers["St-Prex"] - this.props.piers["Rolle"]) /100, backgroundColor: "#e2f2f8", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="pier-text-format pr-1">
             <span className="pier-text-2">Rolle</span>
            </div>
            <div style={{width: "80px", height: this.props.zoom * (this.props.piers["Morges"] - this.props.piers["St-Prex"]) /100, backgroundColor: "#e2f2f8", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="pier-text-format pr-1">
             <span className="pier-text">St-Prex</span>
            </div>
            <div style={{width: "80px", height: this.props.zoom * (this.props.piers["St-Sulpice"] - this.props.piers["Morges"]) /100, backgroundColor: "#c6e5ef", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="pier-text-format pr-1">
             <span className="pier-text-2">Morges</span>
            </div>
            <div style={{width: "80px", height: this.props.zoom * (this.props.piers["Thonon"] - this.props.piers["St-Sulpice"]) /100, backgroundColor: "#e2f2f8", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="pier-text-format pr-1">
             <span className="pier-text">St-Sulpice</span>
            </div>
            <div style={{width: "80px", height: this.props.zoom * (this.props.piers["Evian"] - this.props.piers["Thonon"]) /100, backgroundColor: "#c6e5ef", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="pier-text-format pr-1">
             <span className="pier-text-2">Thonon</span>
            </div>
            <div style={{width: "80px", height: this.props.zoom * (this.props.piers["Lausanne"] - this.props.piers["Evian"]) /100, backgroundColor: "#e2f2f8", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="pier-text-format pr-1">
             <span className="pier-text-2">Evian</span>
            </div>
            <div style={{width: "80px", height: this.props.zoom * (this.props.piers["Pully"] - this.props.piers["Lausanne"]) /100, backgroundColor: "#c6e5ef", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff", alignContent: "center", display: "grid"}} className="pr-1">
              <MDBRow end className="m-0">
              <span className="pier-text-2">Lausanne</span><br/>
              </MDBRow>
              <MDBRow end className="m-0">
              <span className="pier-text">Ouchy</span>
              </MDBRow>
            </div>
            <div style={{width: "80px", height: this.props.zoom * (this.props.piers["Lutry"] - this.props.piers["Pully"]) /100, backgroundColor: "#e2f2f8", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="pier-text-format pr-1">
             <span className="pier-text">Pully</span>
            </div>
            <div style={{width: "80px", height: this.props.zoom * (this.props.piers["Cully"] - this.props.piers["Lutry"]) /100, backgroundColor: "#c6e5ef", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="pier-text-format pr-1">
             <span className="pier-text">Lutry</span>
            </div>
            <div style={{width: "80px", height: this.props.zoom * (this.props.piers["Rivaz"] - this.props.piers["Cully"]) /100, backgroundColor: "#e2f2f8", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="pier-text-format pr-1">
             <span className="pier-text">Cully</span>
            </div>
            <div style={{width: "80px", height: this.props.zoom * (this.props.piers["Vevey"] - this.props.piers["Rivaz"]) /100+1, backgroundColor: "#e2f2f8", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="pier-text-format pr-1">
             <span className="pier-text">Rivaz</span>
            </div>
            <div style={{width: "80px", height: this.props.zoom * (this.props.piers["La Tour"] - this.props.piers["Vevey"]) /100, backgroundColor: "#c6e5ef", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="pier-text-format pr-1">
             <span className="pier-text-2">Vevey</span>
            </div>
            <div style={{width: "80px", height: this.props.zoom * (this.props.piers["Clarens"] - this.props.piers["La Tour"]) /100, backgroundColor: "#e2f2f8", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="pier-text-format pr-1">
             <span className="pier-text">La Tour</span>
            </div>
            <div style={{width: "80px", height: this.props.zoom * (this.props.piers["Montreux"] - this.props.piers["Clarens"]) /100, backgroundColor: "#e2f2f8", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="pier-text-format pr-1">
             <span className="pier-text">Clarens</span>
            </div>
            <div style={{width: "80px", height: this.props.zoom * (this.props.piers["Territet"] - this.props.piers["Montreux"]) /100, backgroundColor: "#c6e5ef", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="pier-text-format pr-1">
             <span className="pier-text-2">Montreux</span>
            </div>
            <div style={{width: "80px", height: this.props.zoom * (this.props.piers["Chillon"] - this.props.piers["Territet"]) /100, backgroundColor: "#e2f2f8", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="pier-text-format pr-1">
             <span className="pier-text">Territet</span>
            </div>
            <div style={{width: "80px", height: this.props.zoom * (this.props.piers["Villeneuve"] - this.props.piers["Chillon"]) /100, backgroundColor: "#c6e5ef", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="pier-text-format pr-1">
             <span className="pier-text">Chillon</span>
            </div>
            <div style={{width: "80px", height: this.props.zoom * (this.props.piers["Bouveret"] - this.props.piers["Villeneuve"]) /100, backgroundColor: "#e2f2f8", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="pier-text-format pr-1">
             <span className="pier-text">Villeneuve</span>
            </div>
            <div style={{width: "80px", height: this.props.zoom * (this.props.piers["St-Gingolph"] - this.props.piers["Bouveret"]) /100, backgroundColor: "#c6e5ef", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="pier-text-format pr-1">
             <span className="pier-text-2">Bouveret</span>
            </div>
            <div style={{width: "80px", height: this.props.zoom * (this.props.piers["Fin"] - this.props.piers["St-Gingolph"]) /100, backgroundColor: "#e2f2f8", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="pier-text-format pr-1">
             <span className="pier-text">St-Gingolph</span>
            </div>
          </div>

        </>
    );
  }
}
export default LineComponent;
