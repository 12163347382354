import React from 'react';

import panzoom from 'pan-zoom';

import {
  MDBIcon
} from 'mdbreact';

class TimeComponent extends React.Component {

  render() {
    const piersHeaderCellWidth = 80;
    let cellHeight = "50px"
    return (
        <>
          <div style={{transform: `translateX(${this.props.x + piersHeaderCellWidth}px)`, width: this.props.zoom / 100 * this.props.maxmodelx}} className="time-line">
            <div style={{width: this.props.zoom * (this.props.hours[5]-this.props.hours[4])/100, height: cellHeight, backgroundColor: "#c6e5ef", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="d-flex align-items-center justify-content-center">
             <span className="time-text-component font-weight-bold">4:00</span>
            </div>
            <div style={{width: this.props.zoom * (this.props.hours[6]-this.props.hours[5])/100, height: cellHeight, backgroundColor: "#c6e5ef", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="d-flex align-items-center justify-content-center">
             <span className="time-text-component font-weight-bold">5:00</span>
            </div>
            <div style={{width: this.props.zoom * (this.props.hours[7]-this.props.hours[6])/100, height: cellHeight, backgroundColor: "#c6e5ef", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="d-flex align-items-center justify-content-center">
             <span className="time-text-component font-weight-bold">6:00</span>
            </div>
            <div style={{width: this.props.zoom * (this.props.hours[8]-this.props.hours[7])/100, height: cellHeight, backgroundColor: "#c6e5ef", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="d-flex align-items-center justify-content-center">
             <span className="time-text-component font-weight-bold">7:00</span>
            </div>
            <div style={{width: this.props.zoom * (this.props.hours[9]-this.props.hours[8])/100, height: cellHeight, backgroundColor: "#c6e5ef", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="d-flex align-items-center justify-content-center">
             <span className="time-text-component font-weight-bold">8:00</span>
            </div>
            <div style={{width: this.props.zoom * (this.props.hours[10]-this.props.hours[9])/100, height: cellHeight, backgroundColor: "#c6e5ef", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="d-flex align-items-center justify-content-center">
             <span className="time-text-component font-weight-bold">9:00</span>
            </div>
            <div style={{width: this.props.zoom * (this.props.hours[11]-this.props.hours[10])/100, height: cellHeight, backgroundColor: "#c6e5ef", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="d-flex align-items-center justify-content-center">
             <span className="time-text-component font-weight-bold">10:00</span>
            </div>
            <div style={{width: this.props.zoom * (this.props.hours[12]-this.props.hours[11])/100, height: cellHeight, backgroundColor: "#c6e5ef", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="d-flex align-items-center justify-content-center">
             <span className="time-text-component font-weight-bold">11:00</span>
            </div>
            <div style={{width: this.props.zoom * (this.props.hours[13]-this.props.hours[12])/100, height: cellHeight, backgroundColor: "#c6e5ef", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="d-flex align-items-center justify-content-center">
             <span className="time-text-component font-weight-bold">12:00</span>
            </div>
            <div style={{width: this.props.zoom * (this.props.hours[14]-this.props.hours[13])/100, height: cellHeight, backgroundColor: "#c6e5ef", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="d-flex align-items-center justify-content-center">
             <span className="time-text-component font-weight-bold">13:00</span>
            </div>
            <div style={{width: this.props.zoom * (this.props.hours[15]-this.props.hours[14])/100, height: cellHeight, backgroundColor: "#c6e5ef", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="d-flex align-items-center justify-content-center">
             <span className="time-text-component font-weight-bold">14:00</span>
            </div>
            <div style={{width: this.props.zoom * (this.props.hours[16]-this.props.hours[15])/100, height: cellHeight, backgroundColor: "#c6e5ef", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="d-flex align-items-center justify-content-center">
             <span className="time-text-component font-weight-bold">15:00</span>
            </div>
            <div style={{width: this.props.zoom * (this.props.hours[17]-this.props.hours[16])/100, height: cellHeight, backgroundColor: "#c6e5ef", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="d-flex align-items-center justify-content-center">
             <span className="time-text-component font-weight-bold">16:00</span>
            </div>
            <div style={{width: this.props.zoom * (this.props.hours[18]-this.props.hours[17])/100, height: cellHeight, backgroundColor: "#c6e5ef", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="d-flex align-items-center justify-content-center">
             <span className="time-text-component font-weight-bold">17:00</span>
            </div>
            <div style={{width: this.props.zoom * (this.props.hours[19]-this.props.hours[18])/100, height: cellHeight, backgroundColor: "#c6e5ef", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="d-flex align-items-center justify-content-center">
             <span className="time-text-component font-weight-bold">18:00</span>
            </div>
            <div style={{width: this.props.zoom * (this.props.hours[20]-this.props.hours[19])/100, height: cellHeight, backgroundColor: "#c6e5ef", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="d-flex align-items-center justify-content-center">
             <span className="time-text-component font-weight-bold">19:00</span>
            </div>
            <div style={{width: this.props.zoom * (this.props.hours[21]-this.props.hours[20])/100, height: cellHeight, backgroundColor: "#c6e5ef", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="d-flex align-items-center justify-content-center">
             <span className="time-text-component font-weight-bold">20:00</span>
            </div>
            <div style={{width: this.props.zoom * (this.props.hours[22]-this.props.hours[21])/100, height: cellHeight, backgroundColor: "#c6e5ef", borderBottom: "1px solid #ffffff", borderRight: "2px solid #ffffff"}} className="d-flex align-items-center justify-content-center">
             <span className="time-text-component font-weight-bold">21:00</span>
            </div>
            <div style={{width: this.props.zoom * (this.props.hours["Fin"]-this.props.hours[22])/100, height: cellHeight, backgroundColor: "#c6e5ef", borderBottom: "1px solid #ffffff"}} className="d-flex align-items-center justify-content-center">
             <span className="time-text-component font-weight-bold">22:00</span>
            </div>
          </div>

        </>
    );
  }
}
export default TimeComponent;
