import React from 'react';
import { useOutletContext } from 'react-router-dom';
import InterB2BAxios from '../../../Service/InterB2BAxios';
import { Scrollbar } from "react-scrollbars-custom";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBScrollbar
} from 'mdbreact';

class InfoPart extends React.Component {
  constructor(props) {
    super(props);
    this.interAxios = new InterB2BAxios(this);
    this.state = {infotext: ""};
  };

  componentDidMount() {
    this.onAdditionalInfo();

  }

  componentDidUpdate(prevProps, prevState) {
		if (this.props.line !== prevProps.line ){
          this.setState({infotext: ""});
					this.onAdditionalInfo();
				}
	}

  onAdditionalInfo = () => {
    if (this.props.date === undefined || this.props.line === undefined) {
      this.setState({load: false });
    } else {
      let self = this;
      this.interAxios.get('/v1/search/additionalInfo', {params: {date: this.props.date, line: this.props.line}},  function(response)
      {
          self.setState({infotext: response.data});
      });
    }
  }

  render() {
    return (
      <MDBContainer className="m-0 p-0">
      <MDBScrollbar style={{maxHeight: "65vh"}}>
        <MDBRow className="m-0  padding-mealpart">
          <div dangerouslySetInnerHTML={{__html: this.state.infotext}}/>
        </MDBRow>
        </MDBScrollbar>
      </MDBContainer>
    );
  }
}

export default function InfoPartFunc() {
  const context = useOutletContext();
  return <InfoPart line={context[0]} date={context[1]} {...this} />;
}
