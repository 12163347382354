import React from 'react';

import panzoom from 'pan-zoom';
import zoominicon from '../../../assets/media/images/Magnifying-glass.png'
import zoomouticon from '../../../assets/media/images/Magnifying-glass-minus.png'

import {
  MDBIcon
} from 'mdbreact';

class ImgComponent extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
            x: this.props?.x ? this.props.x : 0,
            y: this.props?.y ? this.props.y : 0,
            zoom: this.props?.zoom ? this.props.zoom : 100
      }
    };

  componentDidMount() {
    let unpanzoom = panzoom(
      document.getElementById('map'),
      e => {
        this.setState((state, props) => {
            let withSize = Math.min(window.innerWidth, 500)
            let newzoom = state.zoom - e.dz*0.2;
            if (newzoom < 100) {
              newzoom = 100;
            }
            if (newzoom >= 201) {
              newzoom = 200;
            }
            /*let newzoom;
            if (e.dz < 0) {
              newzoom = 200;
            } else {
              newzoom = 100;
            }*/
            let newx = state.x + e.dx;
            let newy = state.y + e.dy;
            if (newzoom < 101) {
              newx = 0;
              newy = 0;
            } else {
              if (newx > withSize / 2) {
                newx = withSize / 2;
              }
              if (newx < -withSize *1.1 / 2) {
                newx = -withSize *1.1 / 2
              }
              if (newy > window.innerHeight * 0.25 / 2) {
                newy = window.innerHeight * 0.25 / 2;
              }
              if (newy < -window.innerHeight * 0.35 / 2) {
                newy = -window.innerHeight * 0.35 / 2
              }

            }
            return {
              x: newx,
              y: newy,
              zoom: newzoom
            }
          }
        );

        // zoom delta
        //alert(e.dz);

        // coordinates of the center
/*        console.log(e.x);
        console.log(e.y);

        // type of interaction: mouse or touch
        console.log(e.type);

        // target element event is applied to
        console.log(e.target);

        // original element event started from
        console.log(e.srcElement);

        // original native event object. For inertial updates, this
        // event object may be reused from a previous update
        // due to the fact that we are simulating the inertial changes.
        console.log(e.event);

        // initial coordinates of interaction
        console.log(e.x0);
        console.log(e.y0);

        console.log(e.px0);
        console.log(e.py0);*/

        requestAnimationFrame(() => {
        });
      },
      {
        onStart: e => {},

        onEnd: e => {},

        onDoubleTap: e => {},

        passive: false,
      }
    );
    this.setState({unpanzoom: unpanzoom});
  }


  componentWillUnmount() {
    this.state.unpanzoom();
    window.removeEventListener('resize', this.handleResize);
  }

  onZoomIn = () => {
    this.setState({zoom: 200});
  }

  onZoomOut = () => {
    this.setState({zoom: 100, x: 0, y: 0,});
  }


  render() {
    let withSize = Math.min(window.innerWidth, 500) ; //style={{transform: 'translateX(100px)'}}
    let heightSize = window.innerHeight/2 ;
    return (
        <div id="map">
          <img src={this.props.image} style={{transform: `translate(${this.state.x}px, ${this.state.y}px)`, width: this.state.zoom * withSize / 100, maxHeight: this.state.zoom * heightSize / 100}}/>
          <button type="button" className="btn btn-flat p-0 m-0" onClick={this.state.zoom === 100 ? this.onZoomIn : this.onZoomOut}>
            <img src={this.state.zoom === 100 ? zoominicon : zoomouticon} className="search-icon"/>
          </button>
        </div>
    );
  }
}
export default ImgComponent;
