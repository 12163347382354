import React from 'react';
import { Scrollbar } from "react-scrollbars-custom";
import InterB2BAxios from '../../Service/InterB2BAxios';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBScrollbar
} from 'mdbreact';

class InfosPopup extends React.Component {
  constructor(props) {
    super(props);
    this.interAxios = new InterB2BAxios(this);
    this.state = {infotext: ""};
  };

  componentDidMount() {
    this.onInfos();
  };

  onInfos = () => {
    let self = this;
    this.interAxios.get('/v1/infos', function(response)
    {
        self.setState({infotext: response.data});
    });
  }

  render() {
    //console.log(this.state.infotext);
    let list = Object.entries(this.state.infotext).map(([key, value]) =>
                                                <MDBRow start key={key} className="m-0 pl-1 py-2">
                                                  <MDBCol size="4" top className="m-0 pl-0" style={{paddingRight: "10px"}}>
                                                    <span className="bold-text font-17px">{key} </span>
                                                  </MDBCol>
                                                  <MDBCol size="8" top className="m-0 p-0">
                                                    <div className="news-text font-17px" dangerouslySetInnerHTML={{__html: value}}/>
                                                  </MDBCol>
                                                </MDBRow>);
    return(
      <MDBContainer className="m-0 p-0">
      <MDBScrollbar className="infospopup-scrollbar">
          {list}
        </MDBScrollbar>
        </MDBContainer>
    );
  }
}

export default InfosPopup;
