import React from 'react';
import { useOutletContext } from 'react-router-dom';
//import { Document, Page } from 'react-pdf';
//import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import InterB2BAxios from '../../../Service/InterB2BAxios';
import ImgComponent from './ImgComponent';

import { boat_1, boat_2, boat_3, boat_4, boat_5, boat_6, boat_7, boat_8, boat_9, boat_10, boat_11, boat_12, boat_13, boat_14, boat_15, boat_16 } from '../../boats';

/*
import boat_1 from '../../../assets/media/images/boats-png/La_Suisse_1.png'
import boat_2 from '../../../assets/media/images/boats-png/Simplon_2.png'
import boat_3 from '../../../assets/media/images/boats-png/Rhone_3.png'
import boat_4 from '../../../assets/media/images/boats-png/Savoie_4.png'
import boat_5 from '../../../assets/media/images/boats-png/italie_5.png'
import boat_6 from '../../../assets/media/images/boats-png/Vevey_6.png'
import boat_7 from '../../../assets/media/images/boats-png/Montreux_7.png'
import boat_8 from '../../../assets/media/images/boats-png/Ville_de_Geneve_8.png'
import boat_9 from '../../../assets/media/images/boats-png/Guisan_Dunant_9.png'
import boat_10 from '../../../assets/media/images/boats-png/Leman_10.png'
import boat_11 from '../../../assets/media/images/boats-png/Coppet_Geneve_11.png'
import boat_12 from '../../../assets/media/images/boats-png/Lavaux_Morges_Valais_12.png'
import boat_13 from '../../../assets/media/images/boats-png/Colvert_13.png'
import boat_14 from '../../../assets/media/images/boats-png/Lavaux_Morges_Valais_12.png'
*/

import {
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBScrollbar
} from 'mdbreact';


class MapPart extends React.Component {
  constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this);
		this.state = {x: 0,
                  y: 0,
                  zoom: 100,
                  load: false
									};
	};

	componentDidMount() {
		this.onMap();
	};

  componentDidUpdate(prevProps, prevState) {
		if (this.props.line !== prevProps.line ){
          this.setState({load: false});
					this.onMap();
				}
	}

  onMap = () => {
    if (this.props.date === undefined || this.props.line === undefined) {
      this.setState({load: false });
    } else {
      let self = this;
      this.interAxios.get('/v1/search/map', {params: {date: this.props.date, line: this.props.line}},  function(response)
      {
          self.setState({map: response.data,
                        load: true});
      });
    }
	}





  render() {
    let boat = ["", boat_1, boat_2, boat_3, boat_4, boat_5, boat_6, boat_7, boat_8, boat_9, boat_10, boat_11, boat_12, boat_13, boat_14, boat_15, boat_16];
    return (
        <>
        {this.state.load ? <>
        <MDBScrollbar style={{height: this.props.heightSize}}>
        <div className="pdf-bild d-flex align-items-center justify-content-center pb-4" style={{minHeight: "40vh"}}>
          <ImgComponent zoom={this.state.zoom} x={this.state.x} y={this.state.y} image={this.state.map?.mapPictureData} searchIcon={true}/>
        </div>
        <MDBRow className="m-0 pb-2 pt-0 iphone-se">
          <MDBCol size="5">
          </MDBCol>
          <MDBCol size="7" className="m-0 pl-2 pr-0">
            <MDBRow center className="m-0 p-0">
              <MDBCol>
                <img className="boat-icon" src={boat[this.state.map?.batId]}/>
              </MDBCol>
            </MDBRow>
            <MDBRow center className="boat-name-row m-0 pt-2 pb-4">
              <span className="font-weight-bolder font-italic">{this.state.map?.boatName}</span>
            </MDBRow>
          </MDBCol>
        </MDBRow>
        </MDBScrollbar>
        </>:false}
        </>
    );
  }
}
export default function MapPartFunc() {
  const context = useOutletContext();
  return <MapPart line={context[0]} date={context[1]} {...this} />;
}
