import React from 'react';
import { MDBModal,
         MDBModalBody,
         MDBModalHeader
       } from 'mdbreact';
import NewsDetailsPage from '../../pages/NewsDetailsPage';

class NewsModal extends React.Component {
  state = { isOpen: false }

  onOpen = () => {
    this.setState({isOpen: true});
  }

  onClose = () => {
    this.setState({isOpen: false});
  }

  render() {

    return (
        <div className={this.props.nyit ? "newsmodal newsmodal-visible" : "newsmodal"}>
            <NewsDetailsPage news={this.props.news}/>
        </div>
    );
  }
}

export default NewsModal;