
import InterB2BAxios from '../../Service/InterB2BAxios';

export function checkAuthenticated(setAuthenticated) {
    let interAxios = new InterB2BAxios(this, 'auth.js');
    //setTimeout(function() {
        interAxios.get('/authenticated', function(response) {
            setAuthenticated(response.data === "OK");
        }, function(error) {
            setAuthenticated(false);
        })
    //}, 1000);
    return () => {
        interAxios.cancel();
    }
}
