import React, { parentonent } from "react";

import axios from 'axios';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

// Paraméterek:
// process.env.REACT_APP_BACKEND_URL

/* Használat:
 * import InterB2BAxios from '../Service/InterB2BAxios';
 * nem kell: import axios from 'axios';
 * nem kell: cookies használata, token és myUserId átrakása state-be (hacsak más miatt nem szükséges)
 *
 * A komponens konstruktorában:
 *
 * this.interAxios = new InterB2BAxios(this);
 *
 * Ahol axios GET-hívás szükséges, ott a következőt kell kiadni:
 *
 * this.interAxios.get('/api/url/tobbi/resze', function(response) {
 *   // amit akkor kell csinálni, ha minden oké.
 * })
 *
 * Ahol axios POST-hívás szükséges, ott a következőt kell kiadni:
 * this.interAxios.post('/api/url/tobbi/resze', data_amit_elkuldunk_pl_formData, [extraConfig], function(response) {
 *   // amit akkor kell csinálni, ha minden oké.
 * }[, function(error) {
 *   // amit akkor kell csinálni, ha nem sikerült a hívás (pl. signin) - nem kötelező
 * }]);
 *
 * extraConfig paraméterben lévő adatokat hozzáfésüli az axios configjához (token és canceltoken)
 * Az extraConfig formátuma:
 * {
 *   params: { 'a': 'b'},
 *   headers: { 'c': 'd'}
 * }
 *
 * Ha a komponens lekerül a képernyőről (componentWillUnmount), akkor abban
 * hívd meg a this.interAxios.cancel() függvényt
 */

let globalUserData;

export function setGlobalUserData(userData) {
  globalUserData = userData;
}

class InterB2BAxios {
  constructor(parent, name) {
    this.parent = parent;
    this.name = name;
    this.cancelTokenSource = axios.CancelToken.source();
    this.authToken = cookies?.get('authtoken')?.token;
    this.errorHandling.bind(this);
    this.catchFunction.bind(this);
    this.cancelHandling.bind(this);
    this.get.bind(this);
    this.post.bind(this);
    this.delete.bind(this);
    this.put.bind(this);
    //this.refreshUserData.bind(this);
  }

  get(urlEnding, extraConfig, thenFunction, errorFunction) {
    if (typeof extraConfig === "function") {
      errorFunction = thenFunction;
      thenFunction = extraConfig;
      extraConfig = undefined;
    }

    let self = this;
    let config = this.addDefaultConfig(extraConfig);

//    console.log('>>>Starting GET ('+this.name+'): ' + urlEnding + ' with token: ' + this.shortJwt())
    axios.get(process.env.REACT_APP_BACKEND_URL+urlEnding, config)
		.then(thenFunction)
//    .then((response) => {console.log('<<<Finished GET: ' + urlEnding + ' with token: ' + this.shortJwt()); thenFunction(response);})
//    .catch((error) => {console.log('<<<Error GET: ' + urlEnding + ' with token: ' + this.shortJwt()); this.catchFunction(this, error);});
    .catch((error) => self.catchFunction(self, error, errorFunction));
  }

  post(urlEnding, data, extraConfig, thenFunction, errorFunction) {
    if (typeof extraConfig === "function") { // extraConfig is not given
      errorFunction = thenFunction;
      thenFunction = extraConfig;
      extraConfig = undefined;
    }

    let self = this;
    let config = this.addDefaultConfig(extraConfig);
    //console.log('>>>Starting POST: ' + urlEnding + ' with token: ' + this.shortJwt())
    axios.post(process.env.REACT_APP_BACKEND_URL+urlEnding, data, config)
		.then(thenFunction)
//    .then((response) => {console.log('<<<Finished POST: ' + urlEnding + ' with token: ' + this.shortJwt()); thenFunction(response);})
//    .catch((error)=>{console.log('no way!');})
//    .catch((error) => {console.log('<<<Error GET: ' + urlEnding + ' with token: ' + this.shortJwt()); this.catchFunction(this, error);});
//    .catch((error) => {console.log('<<<Error POST: ' + urlEnding + ' with token: ' + this.shortJwt()); this.catchFunction(this, error);});
    .catch((error) => self.catchFunction(self, error, errorFunction));

  }


  delete(urlEnding, extraConfig, thenFunction) {
    if (typeof extraConfig === "function") {
      thenFunction = extraConfig;
      extraConfig = undefined;
    }

    let self = this;
    let config = this.addDefaultConfig(extraConfig);

    axios.delete(process.env.REACT_APP_BACKEND_URL+urlEnding, config)
    .then(thenFunction)

    .catch((error) => self.catchFunction(self, error));
  }

  put(urlEnding, data, extraConfig, thenFunction, errorFunction) {
    if (typeof extraConfig === "function") { // extraConfig is not given
      errorFunction = thenFunction;
      thenFunction = extraConfig;
      extraConfig = undefined;
    }

    let self = this;
    let config = this.addDefaultConfig(extraConfig);

    axios.put(process.env.REACT_APP_BACKEND_URL+urlEnding, data, config)
		.then(thenFunction)
    .catch((error) => self.catchFunction(self, error, errorFunction));
  }

  cancel(message) {
    this.cancelTokenSource.cancel(message);
  }

  // Csak a UserDataComponent.onSignIn()-ből kell hívni
  refreshUserData(userData) {
    //console.log("refreshUserData ("+this.name+")");
    if (userData !== undefined) {
      this.authToken = userData.token();
      //console.log("token=" + userData.token());
    } else {
      console.log('userData is not defined!');
    }
  }

  // should be private

  shortJwt() {
    if (this.authToken === undefined)
      return 'no_token';
    let parts = this.authToken.split('.');
    //console.log(parts[2]);
    return parts[2].substring(0,10);
  }

  addDefaultConfig(extraConfig) {
    let config = extraConfig ?? {};
    if (this.authToken !== undefined) {
      config.headers = {...config.headers, 'Authorization': 'Bearer ' + this.authToken};
    }
    config.cancelToken = this.cancelTokenSource.token;
    config.validateStatus = function(status) { return status >= 200 && status < 250 };
    return config;
  }

  catchFunction(self, error, errorFunction) {
    console.log('error')
    if (axios.isCancel(error)) {
      self.cancelHandling(error);
    } else {
      if (errorFunction === undefined) {
        self.errorHandling(self, error);
      } else {
        errorFunction(error);
      }
    }
  }

  errorHandling(self, error) {
    if (error.response?.status === 401) {
      // csak abban az interaxiosban lesz beállítva, ami a UserDataComponenthez tartozik
      if (globalUserData?.onUnauthorizedError !== undefined) {
        globalUserData.onUnauthorizedError();
      }
      console.log('401-es hiba');
    }
    console.log(error + 'Problem');
  }

  cancelHandling(error) {
    console.log('Request canceled', error.message ?? '');
  }

}

export default InterB2BAxios;
