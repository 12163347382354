import React, { Component } from "react";
import ReactDOM from 'react-dom';

class SplashScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  };

  render() {
    return (
      <div className="splash">

      </div>
    );
  }
}
export default SplashScreen;
