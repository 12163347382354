import React from 'react';
import { MDBModal,
         MDBModalBody,
         MDBModalHeader
       } from 'mdbreact';

import infos from "../../assets/media/images/infos1.png";
class Popup extends React.Component {
  state = { isOpen: false }

  onOpen = () => {
    this.setState({isOpen: true});
  }

  onClose = () => {
    this.setState({isOpen: false});
  }

  render() {
    return (
      <>
        <button type="button" className="btn btn-flat p-0 m-0" onClick={this.onOpen}><img className="infos-btn" src={infos}/></button>
        <MDBModal
             isOpen={this.state.isOpen}
             toggle={this.onClose}
             side position="top-left">
          <MDBModalHeader toggle={this.onClose}>
          </MDBModalHeader>
          <MDBModalBody className="modal-padding">
            {this.props.content}
          </MDBModalBody>
        </MDBModal>
      </>
    );
  }
}

export default Popup;
