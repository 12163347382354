import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Cookies from 'universal-cookie';

import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBFileInput,
    MDBBtn,
  } from 'mdbreact';


import { checkAuthenticated } from '../../components/admin/auth';
import InterB2BAxios from '../../Service/InterB2BAxios';

function AdminPage(props) {
    const [authenticated, setAuthenticated] = useState(null);
    const [file, setFile] = useState(null);
    const [errorMessages, setErrorMessages] = useState([]);
    const cookies = new Cookies();
    const navigate = useNavigate();
    const noFileSelectedMessage = 'No file selected!';
    const fileLargerThan100Mb = 'File is larger than 100Mb';
    const maxUploadSize = 100000000;

    let interAxios = new InterB2BAxios(this, 'AdminPage');

    useEffect(() => {
        return checkAuthenticated(setAuthenticated);
    })

    function doLogout(e) {
        cookies.remove('authtoken', { path: '/admin' });
        navigate('/admin/login')
    }

    function doUpload(e) {
        if (file === null) {
            setErrorMessages([noFileSelectedMessage]);
            return;
        }
        if (file.size > maxUploadSize) {
            setErrorMessages([fileLargerThan100Mb]);
            return;
        }
        setErrorMessages(["Processing files might take minutes(!). Please wait..."]);
        const formData = new FormData();
        formData.append("file", file);
        interAxios.post('/admin/upload', formData, function(response) {
            //console.log(response.data);
            setErrorMessages(["Processing finished successfully!"]);
        }, function(error) {
            console.log(error.response.data);
            setErrorMessages([error.response.data]);
        })
    }

    function fileInputHandler(e) {
        console.log(e);
//        em.push(...errorMessages);
//        let em = [];
        if (e.length === 0) {
            setErrorMessages([noFileSelectedMessage]);
            return;
        } 
        if (e[0].type !== 'application/x-zip-compressed' && e[0].type !== 'application/zip') {
            setErrorMessages(["File is not a ZIP file!"]);
            return;
        }
        if(e[0].size > maxUploadSize) {
            setErrorMessages([fileLargerThan100Mb]);
            setFile(e[0]);
            return;
        }
        setFile(e[0]);
        setErrorMessages([]);
    }

    if (authenticated === null) {
        return <div>Please wait while loading...</div>
    }
    if (!authenticated) {
        navigate('/admin/login')
    }
    return <div>
        <MDBContainer>
            <MDBRow>
                <h1>Admin area</h1>
                <MDBBtn onClick={doLogout}>Logout</MDBBtn>
            </MDBRow>
            <MDBRow>
                {errorMessages}
            </MDBRow>
            <MDBRow>
                <MDBFileInput reverse getValue={fileInputHandler}/>
            </MDBRow>
            <MDBRow>
                <MDBBtn onClick={doUpload}>Upload</MDBBtn>
            </MDBRow>
        </MDBContainer>
    </div>
}

export default AdminPage;
