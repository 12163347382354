import React from 'react';
import boat from '../../assets/media/images/ship.png'
import {
  MDBRow,
  MDBCol,
  MDBIcon
} from 'mdbreact';

class BoatDisplay extends React.Component {
  render() {
    return(
      <div className="boatdispaly">
        <MDBRow className="m-0 p-0" style={{height: "100%"}}>
          <MDBCol size="3" className="text-center p-0" middle>
            <img className="boat-icon" src={boat}/>
          </MDBCol>
          <MDBCol size="9" className="text-left pt-0 px-0" middle>
            <span className="result-boat-name-text text-white font-italic">{this.props.boat}</span>
          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}

export default BoatDisplay;
