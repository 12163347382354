import React from 'react';
import { Outlet } from 'react-router-dom';
import InfosPopup from '../components/popup/InfosPopup';
import Popup from '../components/popup/Popup';
import ReturnButton from '../components/ReturnButton';

import styles from '../assets/scss/modules/pagetemplate.module.scss';
import{
	MDBRow,
	MDBCol,
	MDBContainer
} from 'mdbreact';

class PageTemplate extends React.Component {
  render() {
	let titleClassName = "";
	if (this.props.resultPage) {
		titleClassName = "resultpage-template-title-text";
	} else {
		titleClassName = "template-title-text";
	}
    return (
			<>
				<div className="template-header d-flex align-items-center justify-content-center">
					<MDBRow className="m-0 template-header-row align-items-center">
						<MDBCol className="nav-left">
							<ReturnButton
									isSideNavOpen={this.props.isSideNavOpen}
									handleSideNavToggle={this.props.handleSideNavToggle}/>
						</MDBCol>
						<MDBCol className="nav-title">
							<span className={titleClassName}>{this.props.title}</span>
						</MDBCol>
						<MDBCol className="nav-right">
							<Popup label="Infos" content={<InfosPopup/>} />
						</MDBCol>
					</MDBRow>
				</div>
				<Outlet/>
	 		</>

    );
  }
}

export default PageTemplate;
