import React from 'react';
import { Picker } from 'react-mobile-style-picker';
import { BrowserView, MobileView, isBrowser, isMobile, isSafari } from 'react-device-detect';
import { browserName, CustomView } from 'react-device-detect';
import 'react-mobile-style-picker/dist/index.css'
import '../../assets/scss/modules/verticalpicker.modules.scss'

/*
import boat_1 from '../../assets/media/images/boats-png/La_Suisse_1.png'
import boat_2 from '../../assets/media/images/boats-png/Simplon_2.png'
import boat_3 from '../../assets/media/images/boats-png/Rhone_3.png'
import boat_4 from '../../assets/media/images/boats-png/Savoie_4.png'
import boat_5 from '../../assets/media/images/boats-png/italie_5.png'
import boat_6 from '../../assets/media/images/boats-png/Vevey_6.png'
import boat_7 from '../../assets/media/images/boats-png/Montreux_7.png'
import boat_8 from '../../assets/media/images/boats-png/Ville_de_Geneve_8.png'
import boat_9 from '../../assets/media/images/boats-png/Guisan_Dunant_9.png'
import boat_10 from '../../assets/media/images/boats-png/Leman_10.png'
import boat_11 from '../../assets/media/images/boats-png/Coppet_Geneve_11.png'
import boat_12 from '../../assets/media/images/boats-png/Lavaux_Morges_Valais_12.png'
import boat_13 from '../../assets/media/images/boats-png/Colvert_13.png'
import boat_14 from '../../assets/media/images/boats-png/Lavaux_Morges_Valais_12.png'
*/
import { boat_1_small, 
  boat_2_small, 
  boat_3_small, 
  boat_4_small, 
  boat_5_small, 
  boat_6_small, 
  boat_7_small, 
  boat_8_small, 
  boat_9_small, 
  boat_10_small, 
  boat_11_small, 
  boat_12_small, 
  boat_13_small, 
  boat_14_small, 
  boat_15_small, 
  boat_16_small } from '../boats';
import { boat_1, boat_2, boat_3, boat_4, boat_5, boat_6, boat_7, boat_8, boat_9, boat_10, boat_11, boat_12, boat_13, boat_14, boat_15, boat_16 } from '../boats';
/*
import boat_1 from '../../assets/media/images/boats-png/01_La_Suisse.png'
import boat_2 from '../../assets/media/images/boats-png/02_Simplon.png'
import boat_3 from '../../assets/media/images/boats-png/03_Vevey.png'
import boat_4 from '../../assets/media/images/boats-png/04_Savoie.png'
import boat_5 from '../../assets/media/images/boats-png/05_Italie.png'
import boat_6 from '../../assets/media/images/boats-png/06_Montreux.png'
import boat_7 from '../../assets/media/images/boats-png/07_14_Guisan_Dunant.png'
import boat_8 from '../../assets/media/images/boats-png/08_Ville_de_Geneve.png'
import boat_9 from '../../assets/media/images/boats-png/09_Leman.png'
import boat_10 from '../../assets/media/images/boats-png/empty.png'
import boat_11 from '../../assets/media/images/boats-png/11_13_Lavaux_Morges_Valais.png'
import boat_12 from '../../assets/media/images/boats-png/empty.png'
import boat_13 from '../../assets/media/images/boats-png/11_13_Lavaux_Morges_Valais.png'
import boat_14 from '../../assets/media/images/boats-png/07_14_Guisan_Dunant.png'
import boat_15 from '../../assets/media/images/boats-png/empty.png'
import boat_16 from '../../assets/media/images/boats-png/16_Rhone.png'
*/



import {
  MDBRow,
  MDBCol,
  MDBIcon
} from 'mdbreact';


class VerticalPicker extends React.Component {

  render() {

    let verticalItems = this.props.list.map((item, index) => {
      let mainPierNames = ["Genève (tous)", "Nyon", "Yvoire", "Rolle", "Morges", "Thonon", "Evian", "Lausanne", "Vevey", "Montreux", "Bouveret"];
      let extraItemClassName = "";
      if (mainPierNames.includes(item.name)) {
        extraItemClassName = "font-weight-bold";
      }
      if (item.pictureData !== undefined) {
        extraItemClassName = "font-weight-bold";
      }
      let itemClassName = extraItemClassName + " " + this.props.itemClassName;

      let itemRowComponent;
      if (item.pictureData !== undefined) {
        // This is a boat vertical picker
        itemRowComponent = <MDBRow className="m-0 p-0">
        <MDBCol className="boat-verticalpicker-item-leftcol text-left" middle>
          {item.name}
        </MDBCol>
        <MDBCol className="boat-verticalpicker-item-rightcol text-center" middle>
          <img className="boat-img" src={item.name === "La Suisse" ? boat_1_small :
                                        item.name === "Simplon" ? boat_2_small :
                                        item.name === "Vevey" ? boat_3_small :
                                        item.name === "Savoie" ? boat_4_small :
                                        item.name === "Italie" ? boat_5_small :
                                        item.name === "Montreux" ? boat_6_small :
                                        item.name === "Général-Guisan" ? boat_7_small :
                                        item.name === "Ville-de-Genève" ? boat_8_small :
                                        item.name === "Léman" ? boat_9_small :
                                        item.name === "Navibus" ? boat_10_small :
                                        item.name === "Valais" ? boat_11_small :
                                        item.name === "Col-Vert" ? boat_12_small :
                                        item.name === "Lavaux" ? boat_13_small :
                                        item.name === "Henry-Dunant" ? boat_14_small :
                                        item.name === "Lausanne" ? boat_15_small :
                                        item.name === "Rhône" ? boat_16_small :
                                        item.pictureData}/>
        </MDBCol>
      </MDBRow>;
      } else {
        // This is a pier vertical picker
        itemRowComponent = <MDBRow className="m-0 p-0">
          <MDBCol className="boat-verticalpicker-item-leftcol text-left" middle size="4">
            {item.name}
          </MDBCol>
          <MDBCol className="text-center" middle size="8">

          </MDBCol>
        </MDBRow>;
      }
      return <Picker.Item value={index} key={index}>
          <span className={itemClassName}>
            {itemRowComponent}
          </span>
        </Picker.Item>
      });

    verticalItems.unshift(
      <Picker.Item value={this.props.list.length} key={10000}><span  className={"font-weight-bold "+this.props.itemClassName}><MDBRow className="m-0 p-0">
        <MDBCol className="boat-verticalpicker-item-leftcol pr-0 text-left" middle size="4">
          Tous
        </MDBCol>
        <MDBCol className="p-0 text-center" middle size="8">

        </MDBCol>
      </MDBRow></span></Picker.Item>);

    return (
      <div className="modal-shadow">
        <div className="pierItems-picker">
        <MDBRow className="m-0 p-0 button-row">
          <MDBCol middle size="6" className="m-0 pl-3 text-left">
            <button type="button" className="btn pierItems-picker-button-right" style={{textTransform: "unset"}} onClick={this.props.handleCancel}><span className="font-futura-condensed font-500">Annuler</span></button>
          </MDBCol>
          <MDBCol middle size="6" className="m-0 pr-3 text-right">
            <button type="button" className="btn pierItems-picker-button-left" style={{textTransform: "unset"}} onClick={this.props.handleSelect}><span className="font-futura-condensed font-500">OK</span></button>
          </MDBCol>
        </MDBRow>
        <Picker mode={'vertical'} mask={[.3,1]} onChange={this.props.onChange} value={this.props.value} size={5}>
          {verticalItems}
        </Picker>
        </div>
      </div>
    );
  }
}

export default VerticalPicker;
