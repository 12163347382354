import React from 'react';
import { Outlet, Link } from 'react-router-dom';
import { Scrollbar } from "react-scrollbars-custom";
import NewsItem from './NewsItem';
import InterB2BAxios from '../../Service/InterB2BAxios';
import { getNews } from '../../data/news';
import{
	MDBContainer,
	MDBCard
} from 'mdbreact';

class NewsList extends React.Component {
	constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this);
		this.state = {news: []
									};
	};

	componentDidMount() {
		this.onNews();
	};

	onNews = () => {
		let self = this;
    this.interAxios.get('/v1/news',  function(response)
    {
        self.setState({news: response.data});
    });
	}
  render() {
    let newsItemComponents = this.state.news.filter((item, index) => index < this.state.news.length-1).map((item) =>
            <div key={item.id}>
            <NewsItem data={item} onLink={this.props.onLink} />
            <div className="linie  d-flex align-items-center justify-content-center"/>
            </div>);
		if(this.state.news.length>1){
			let lastItem = this.state.news[this.state.news.length-1];
			newsItemComponents.push(<div  key={lastItem.id}><NewsItem data={lastItem} onLink={this.props.onLink} /></div>);
		}

		return (
      <MDBContainer className="px-d1">
        <MDBCard className="newsItem-card">
					<Scrollbar
										id="scrollbar"
										style={{ width: "100%", height: "55vh"}}
										maximalThumbYSize = {100}
										noScrollX = {true}
					>
	         {newsItemComponents}
					</Scrollbar>
        </MDBCard>
      </MDBContainer>
    );
  }
}

export default NewsList;
