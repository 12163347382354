import React from 'react';
import { useOutletContext } from 'react-router-dom';
import uszogumi from "../../../assets/media/images/NaviPlan-Bouee2.png";
import InterB2BAxios from '../../../Service/InterB2BAxios';
import { Link } from 'react-router-dom';
import { Scrollbar } from "react-scrollbars-custom";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBScrollbar
} from 'mdbreact';

/*
import boat_1 from '../../../assets/media/images/boats-png/La_Suisse_1.png'
import boat_2 from '../../../assets/media/images/boats-png/Simplon_2.png'
import boat_3 from '../../../assets/media/images/boats-png/Rhone_3.png'
import boat_4 from '../../../assets/media/images/boats-png/Savoie_4.png'
import boat_5 from '../../../assets/media/images/boats-png/italie_5.png'
import boat_6 from '../../../assets/media/images/boats-png/Vevey_6.png'
import boat_7 from '../../../assets/media/images/boats-png/Montreux_7.png'
import boat_8 from '../../../assets/media/images/boats-png/Ville_de_Geneve_8.png'
import boat_9 from '../../../assets/media/images/boats-png/Guisan_Dunant_9.png'
import boat_10 from '../../../assets/media/images/boats-png/Leman_10.png'
import boat_11 from '../../../assets/media/images/boats-png/Coppet_Geneve_11.png'
import boat_12 from '../../../assets/media/images/boats-png/Lavaux_Morges_Valais_12.png'
import boat_13 from '../../../assets/media/images/boats-png/Colvert_13.png'
import boat_14 from '../../../assets/media/images/boats-png/Lavaux_Morges_Valais_12.png'
*/

import { boat_1, boat_2, boat_3, boat_4, boat_5, boat_6, boat_7, boat_8, boat_9, boat_10, boat_11, boat_12, boat_13, boat_14, boat_15, boat_16 } from '../../boats';

class BoatPart extends React.Component {

  constructor(props) {
    super(props);
    this.interAxios = new InterB2BAxios(this);
    this.state = {boat: {}};
  };

  componentDidMount() {
    this.onBoat();

  }

  componentDidUpdate(prevProps, prevState) {
		if (this.props.line !== prevProps.line ){
          this.setState({boat: {}});
					this.onBoat();
				}
	}

  onBoat = () => {
    if (this.props.date === undefined || this.props.line === undefined) {
      this.setState({load: false });
    } else {
      let self = this;
      this.interAxios.get('/v1/search/boat', {params: {date: this.props.date, line: this.props.line}},  function(response)
      {
          self.setState({boat: response.data});
      });
    }
  }


  render() {
    let boat = ["", boat_1, boat_2, boat_3, boat_4, boat_5, boat_6, boat_7, boat_8, boat_9, boat_10, boat_11, boat_12, boat_13, boat_14, boat_15, boat_16];
    let list = Object.entries(this.state.boat).filter((item, index) => item[0] !== "name" && item[0] !== "pictureData" && item[0] !== "link" && item[0] !== "batId")
                                              .map(([key, value]) =>
                                                <MDBRow center key={key} className="m-0 padding-left py-0 font-18px">
                                                  <MDBCol size="6" top className="m-0 px-0">
                                                    <span className="bold-text">{key} </span>
                                                  </MDBCol>
                                                  <MDBCol size="6" top className="m-0 px-0">
                                                    <span className="font-300">{value}</span>
                                                  </MDBCol>
                                                </MDBRow>);
    return (
      <div >
      <MDBScrollbar style={{height: this.props.heightSize}}>
      <MDBRow center className="m-0 pt-34px py-2">
        <div middle className="m-0 px-5 text-center">
          <img className="boat-icon-2" src={boat[this.state.boat?.batId]}/>
        </div>
      </MDBRow>
      <MDBRow center className="m-0 pb-17px pt-0">
        <span className="bold-text-italic font-16px">{this.state.boat.name}</span>
      </MDBRow>
      {list}
      {this.state.boat.link !== "" && this.state.boat.link !== undefined ?
      <MDBRow start className="m-0 pt-0 padding-left">
        <MDBCol size="6" top className="m-0 px-0">
          <a href={this.state.boat.link} target="_blank" rel="noopener noreferrer">
            <span className="text-decoration font-18px font-300">Pour en savoir plus</span>
          </a>
        </MDBCol>
      </MDBRow>:false}
      </MDBScrollbar>
      </div>
    );
  }
}

export default function BoatPartFunc() {
  const context = useOutletContext();
  return <BoatPart line={context[0]} date={context[1]} heightSize={context[3]} {...this} />;
}
